export default {
    data() {
        return {
            newReportGroup: { description: "", order: 1, isActive: 1 },
            newReportGroupOrder: 1,
            reportGroupList: [],
            loading: false,
            activeReportGroupList: { id: -1 }
        }
    },
    methods: {
        async GET_INITIAL_REPORT_GROUP_FROM_API(page = 1, limit = 10) {
            this.loading = true;
            const reportId = Number(this.$route.params.reportId);
            const response = await this.$api.get(
                `QuestionGroups/ByReport?reportId=${reportId}&page=${page}&limit=${limit}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.reportGroupList = response.data;
                if (this.$route.params.reportGroupId) {
                    console.log(response);
                    this.activeReportGroupList = response.data.find(d => d.groupId === Number(this.$route.params.reportGroupId))
                }
                if (response.data.length !== 0) {
                    this.newReportGroup.order =
                        response.data[response.data.length - 1].order + 1;
                    this.newReportGroupOrder =
                        response.data[response.data.length - 1].order + 1;
                }
            } else this.reportGroupList = [];
            this.loading = false;
        },
    },
    mounted() {
        this.GET_INITIAL_REPORT_GROUP_FROM_API();
    },
}